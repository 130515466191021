
.Contactus .contacts {
  padding: 50px 0;
}

.contacts .container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 6rem;
}
.contacts h1{
  padding-top: 3rem;
  text-align: center;
}
.contactsp{
  text-align: center;
  padding-bottom: 3rem;
}

.contact-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.map-container {
  flex: 1;
}

.map-container iframe {
  width: 40vw;
  height: 100%;
}

.contact-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 4rem;
}

.contact-box div {
  background: #f9f9f9;
  display: flex;
  gap: 20px;
  text-align: center;
  padding: 1rem 1rem 0 ;
  border-radius: 5px;
}


.contact-box p {
  color: #555;
}

.contact-form {
  flex: 1;
}
.contact-form h3{
  margin-bottom: 3rem;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
.contact-form input{
  background-color: white;
}

.contact-form textarea {
  resize: vertical;
}
.contact-form h3{
  text-align: center;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}
.contact-form .flexSB{
  gap: 3rem;
}

@media screen and (max-width: 800px){
  .contacts .container {
    display: flex;
    flex-direction: column;
  }
  .map-container iframe{
    width: 80vw;
    height: 30vh;
  }
  .contact-content{
    align-items: center;
  }
  
}
