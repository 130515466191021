.about-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.about-item {
  margin: 0 3rem;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.ourmission-main{

  background-color: #A4D9EB;
  padding: 3rem;
}

.about-item-alt .about-item-left {
  order: 2; /* Change the order for alternating items */
}
.recentdata{
  display: flex;
}
.recentdata p{
  display: flex;
  flex-direction: column;
  font-weight: 400;
  padding: 0 4rem;
}


h3{
  font-weight: 700;
}

.about-item-left img {
  max-width: 40vw;
  height: auto;
}
.ourmission{
  padding: 3rem;
}
.ourmission h3{
  padding-bottom: 2rem;
}

.about-recent-content {
  background-color: #f7f7f7;
  padding: 1rem 4rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.about-recent-content h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: 700;
  color: #3491B2;
}

.about-recent-content p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.recentdata{
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;

}
.recentdata p{
  text-align: center;
  font-size: 1.5rem;
}
.recentdata p span{
  font-size: 1rem;
}



.about-item-right {
  flex: 1;
  margin: 4rem;
}

.about-item-right h3 {
  font-size: 24px;
  margin-bottom: 2rem;
}

.about-item-right p {
  font-size: 16px;
  line-height: 1.6;
}
@media (max-width: 768px){
  .about-item{
    flex-direction: column;
    margin: 1rem;
  }
 /* .about-item-left{
  display: none;
 } */
 .recentdata{
  grid-template-columns: 1fr 1fr;
 }
 .about-item-alt .about-item-left {
  order: none; /* Change the order for alternating items */
}
}