

.lastline{
  text-align: center;
  background-color:  #2E3328;
  padding-bottom: 1rem;
}
.lastline a{
  color: white;
  padding: 2rem;
  font-weight: 600;
}

footer {
  background-color:  #2E3328;
  color: white;
}

footer .container {
  /* display: flex; */
  padding-top: 1.5rem;
  /* grid-template-columns: 3fr 2fr 2fr 4fr 3fr; */
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
.container{
  justify-content: space-between;
}


footer .logop span {
  color: #3491B2;
  font-size: 14px;
}

footer .logop p {
  color: white;
  margin: 4vh 0 4vh 0;
}

footer .logop .icon {
  background-color: #3491B2;
  color: white;
  margin-right: 10px;
}
footer .logop .icon:hover{
  background-color: black;
}
.footp div a{
  display: flex;
  flex-direction: row;
}
footer h3 {
  /* margin-bottom: 40px; */
  font-weight: 500;
}

footer span a li,footer span li {
  margin-bottom: 20px;
  color: white;
}

footer span a li:hover, span li :hover{
  color: #3491B2;
}

footer .link li::before {
  /* content: "⟶"; */
  color: rgba(53,100,100,255);
  margin-right: 5px;
}

footer .items {
  margin-bottom: 30px;
}

footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}

footer .items span,
footer .items i {
  font-size: 12px;
  color: rgba(53,100,100,255);
  margin-right: 5px;
  text-transform: capitalize;
}

footer h4 {
  font-weight: 400;
  margin-top: 5px;
}
.box{
  display: flex;
  flex-direction: column;
}
.box h3{
  margin-bottom: 2rem;
}

footer .last span li {
  display: flex;
  margin-bottom: 50px;
}

footer .last i {
  width: 60px;
  font-size: 20px;
  color: rgba(53,100,100,255);
}


.legal {
  text-align: center;
  padding: 2px 5px;
  color: white;
  background-color:  #2E3328;
}

.legal p {
  font-size: 15px;
}

.legal i {
  color: black;
}

.news_container {
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.newsletter_right {
  display: flex;
  align-items: center;
  border: 1px solid white;
}

.newsletter_left {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 400px) {
  .footp{
    width: 90vw;
  }
}

@media screen and (max-width: 800px) {

  .news_container {
    flex-direction: column;
  }
  .box{
    margin: 1rem 0;
  }
  footer .grid{
    justify-items: start;
  }
}

