.aboutback{
  background: #2E3328;
  padding-bottom: 10vh;
}
.aboutHome {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
 
}
.aboutush1{
  font-family: Audiowide, sans-serif;
  text-align: center;
  margin-bottom: 10vh;
  color: #3491B2;
  
}

.aboutHome .container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
}

.left,
.right {
  width: 50%;
  padding: 20px;
}

.text p{
  color: #736767;
  text-align: justify;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.left {
  border-right: 2px solid #ccc;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  display: flex;
  align-items: center;
  gap: 20px;

  padding: 10px;
  border-radius: 8px;
  background-color: #ECF6FA;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 2rem; 
  transition: transform 0.3s ease;
}

.item:hover {
  transform: translateY(-5px);
  /* background: linear-gradient(to right,#3491B3,rgb(219, 172, 139));; */
  /* background: linear-gradient(to right,#3491B3,#3A545E);; */
  background-color: #3491B3;
  color: white;
  .text p{
    color: white;
  }

}
.item .img {
  flex: 0 0 100px;
}

.item img {
  width: 100%;
  border-radius: 8px;
}

.item .text {
  flex: 1;
}

@media (max-width: 768px) {
  .aboutHome .container {
    flex-direction: column;
    margin: 0;
  }
  .aboutHome{
    margin: 0;
    padding: 0;
  }
  .item {
    flex-direction: column;
    margin-top: 2rem; /* Adjust this value as needed */
  }
  .left,
  .right {
    width: 100%;
    border: none;
    margin : 0;
  }
  .item {
    flex-direction: column;
  }
}
