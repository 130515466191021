/* Profile Container */
.profile-container {
    max-width: 800px;
    margin: 4rem auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 5px 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .profile-detail p{
    margin-bottom: 0px;
  }
  /* Profile Title */
  .profile-title {
    font-size: 24px;
    font-weight: bold;
    /* margin-bottom: 20px; */
    color: #333;
  }
  .profile-heading{
    display: flex;
    justify-content: space-between;
  }
  
  /* Horizontal Line */
  .hr-line {
    border: none;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
  }
  
  /* Profile Subsection */
  .profile-subsection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .buttons button{
    margin: 1rem 6rem 4rem;
    background-color: #3491B2;
    color: white;
    border: solid 1px black;
  }
  .buttons button:hover{
    background-color: white;
    color: black;
  }
  
  /* Profile Subtitle */
  .profile-subtitle {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  /* Profile Text */
  .profile-text {
    font-size: 16px;
    color: #555;
  }
  
  /* Profile About */
  .profile-about {
    font-size: 14px;
    color: #777;
    transition: color 0.3s ease;
  }
  
  .profile-about:hover {
    color: #333;
  }
  
  /* Profile Details Container */
  .profile-details-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  /* Profile Detail */
  .profile-detail {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0.4rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .profile-detail:hover {
    transform: translateY(-5px);
  }
  
  /* Profile Detail Label */
  .profile-detail-label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  
  /* Profile Detail Value */
  .profile-detail-value {
    font-size: 14px;
    color: #555;
  }
  
  /* Profile Links */
  .profile-links {
    list-style-type: none;
    padding: 0;
  }
  
  .profile-links li {
    margin-bottom: 5px;
  }
  
  .profile-links a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .profile-links a:hover {
    color: #0056b3;
  }
  