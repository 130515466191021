
.ourcourses {
  text-align: center;
  padding: 10vh 0 0 0;
  color: #3491B2;
  font-weight: 700;
}
  .ourcourses{
    text-align: center;
    text-align: center;
    font-family: Audiowide, sans-serif;
    align-items: center;
    padding: 10vh 0 0 0;
    color: #3491B2;
    font-weight: 700;
  }
  body {
    background-color:#E4EDFE ;
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    color: #000;
    margin: 0;
    padding: 0;
  }

/* body {
  background: red;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  color: #000;
  margin: 0;
  padding: 0;
} */

.cards__container {
  overflow: scroll;
  width: 100%;
  padding: 2rem 0.5rem;
  scrollbar-width: none;
}


.cards {
  width: 100%;
  height: auto;
  width: max-content;
  padding: 0.5rem;
  border-radius: 1rem;
  display: flex;
}

.card{
    color: white;
    display: flex;
    flex-direction: column;
   overflow: hidden;
    background-color: white;
    margin:0  1rem;
    box-shadow: 5px 5px 10px rgb(143, 134, 134);
    min-height: 250px;
    min-width: 250px;
    max-width: 300px;
    color: black;

    
}
.card__desc .detail{
  color: #736767;
  padding: 1rem 0.5rem;
  height: 9rem;
  line-height: 1.2rem;
}


.card:hover {
  transform: scale(1.05);
  transition: 0.5s;
  border-radius: 0 3rem ;    
  background-color: #3491B2;
  color: white;
  .card__desc .detail{
    color: white;
  }
  .card__desc button{
    background-color: white;
    color: #000;
  }
}

.explore{
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
}

.explore a :hover{
  background-color: #3491B2;
  color: white;
  transform: scale(1.2);
  transition: 0.5s;
}

.card__desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
 

}


.card__img {
  width: 100%;
  height: 13rem;
  color: #3491B2;
}

.card__title {
  font-weight: 900;
  height: 4rem;
  font-size: 1.5rem;
  transition: 1.5s;
}
.explore{
  margin: 30vh 5vw;
  text-align: center;
}

.card__desc:hover {
  color: white;
}

.buttons{
  position: relative;
  top: 1rem;
}
.btn{
  background-color: #3491B2;
}
