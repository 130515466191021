.hero {
  background-image: url("../../../../public/images/bg2.jpg");
  background-size: cover;
  /* position: absolute; */
  position:relative;
  left: 0;
  z-index: -1;
  max-width: 100%;
  min-height: 85vh;
  height: auto;
  padding-bottom: 2rem;
  padding-top: 20vh;
  padding-right: 50px;
  color: #fff;
  /* margin-bottom: 8rem; */
}
.hero h1 {
  position: relative;
  font-size: 2rem;
  font-weight: 700;
}
.herobtn{
  z-index: 11000;
}


.hero h3 {
  font: italic;
  position: relative;
  color: #fff;
  font-size: 1.5rem;
  padding-bottom: 2vh;
}

.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

.button {
  padding-top: 3rem;
}
.btns{
  z-index: 200;
  cursor: pointer;
}
.btns>a>button{
  z-index: 100;
}
.row{
  margin: 1rem;
}

@media screen and (max-width: 768px) {
  .margin {
    height: 20vh;
  }

  .hero {
    background-position: center;
    /* padding-top: 50%; */
    /* height: 100vh; */
    background-image: none;
    background-color: #3491b2;

  }

  .hero .row {
    width: 100%;
  }

  .hero h1 {
    font-size: 30px;
    font-weight: 700;
  }

  .hero p {
    display: none;
  }

  .hero h3 {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;
  }
  
}

@media screen and (max-width: 1200px) {
  .margin{
    margin-bottom: 20vh;
  }
  .hero h1 {
    font-size: 2rem;
    font-weight: 700;
  }
  .hero .row {
    width: 100%;
  }

  .hero h3 {
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 1vh;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .margin{
    margin-bottom: 20vh;
  }
  .hero .row {
    width: 100%;
    padding-right: 0px;
   
  }

  .row h1 {
    padding-right: 0;
  }
}

@media screen and (max-width: 450px) {
  .margin{
    margin-bottom: 20vh;
  }
  
}



.line-1 {
  position: relative;
  /* top: 50%;   */
  width: 34rem;
  /* margin: 0 auto; */
  width: max-content;
  border-right: 2px solid rgba(255, 255, 255, .75);
  font-size: 180%;
  /* text-align: center; */
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);

}

/* Animation */
.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 20rem;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, .75);
  }

  to {
    border-right-color: transparent;
  }
}


/* .cards{
    display: flex;
    width: 80%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: auto;
    gap: 2rem;
    padding: .5rem;
    border: 2px solid #5090cc;

}
.ourcourses{
    text-align: center;
    padding: 2rem;
    font-weight: 600;
    color:#5090cc ;
}
.data{
    min-width: 400px;
}
.cards::-webkit-scrollbar {
    display: none;
  } */




/* background animation--------------- */
.area{
  z-index: -11;
}
.circles{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}