@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}

.router__container{
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  scrollbar-width: none;
}
body {
  background-color: #FFFFFF;
  font-family: "Roboto", sans-serif;
}
/*------------global-----------*/
.container {
  max-width: 100%;
  margin: auto;
  
}
.flexSB {
  display: flex;
  justify-content: space-between;
  z-index: 100;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: #3491B2;
  color: #fff;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.row {
  width: 50%;
  
}
button {
  padding: 17px 30px;
  background-color: #fff;
  color: black;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.8rem;
  border: none;
  margin: 10px 10px 0 0;
  border-radius: 0.5rem;
  z-index: 100;
}
button i {
  margin-left: 10px;
}
button:hover {
  box-shadow: none;
}
.primary-btn {
  background-color: black;
  color: #fff;
  cursor: pointer;
  
  font-weight: 600;
}

.primary-btn:hover{
  background-color: white;
  color: black;
}

/*------------global-----------*/
#heading {
  text-align: center;
  padding: 40px 0;
}
#heading h3 {
  font-weight: 700;
  letter-spacing: 1px;
  color: #3491B2;
  font-size: 5vh;
  /* text-transform: uppercase; */
  /* text-align: center; */
}
#heading h1 {
  font-size: 3rem;
  margin: 30px 0;
  /* text-transform: capitalize; */
  /* text-align: center; */
}
p {
  line-height: 30px;
  font-size: 18px;
}

.back {
  /* background-image: url("../public/images/bg3.jpg"); */
  background-size: cover;
  /* background-attachment: fixed; */
  position: absolute;
  /* top: 0; */
  left: 0;
  z-index: -1;
  width: 100%;
  height: 80%;
  color: #fff;
  padding-top: 40vh;
  overflow: hidden;
  overflow-x:hidden;
  overflow-y: hidden;
}

.back h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  font-weight: 400;
}
.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}
.margin {
  margin-top: 50%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  justify-items: center; /* Centers grid items along the row */
  align-items: center; /* Centers grid items along the column */
}

.flex {
  display: flex;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #5090cc;
  width: 100%;
  transition: 0.5s;
}
.outline-btn:hover {
  background-color: #5090cc;
  color: #fff;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
.padding {
  padding: 80px 0;
}
.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .grid2,
  .grid3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .back {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
  #heading h1{
    font-size: 2rem;
  }

  .row {
    width: 100%;
    
  }
  
}

